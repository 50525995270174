<template>

    <div>

        <question-import-file
            :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
            @refetch-data="refetchData"
        />

        <!-- Table Container Card -->
        <b-card
            no-body
            class="mb-0">

            <div class="m-2">

                <!-- Table Top -->
                <b-row>

                    <!-- Per Page -->
                    <b-col
                        cols="12"
                        md="4"
                        class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Mostrar</label>
                        <v-select
                            v-model="perPage"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50"
                        />
                        <label>registros</label>
                    </b-col>

                    <!-- Carga masiva -->
                    <b-col
                        cols="12"
                        md="2"
                        class="d-flex align-items-center justify-content-end mb-1 mb-md-0">
                        <b-button
                            variant="success"
                            @click="isAddNewUserSidebarActive = true">
                            <span class="text-nowrap">Carga masiva</span>
                        </b-button>
                    </b-col>

                    <!-- Search -->
                    <b-col
                        cols="12"
                        md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input
                                v-model="searchQuery"
                                class="d-inline-block mr-1"
                                placeholder="Buscar ..."
                            />
                            <b-button
                                variant="primary"
                                :to="{ name: 'questions-store'}">
                                <span class="text-nowrap">Agregar Pregunta</span>
                            </b-button>
                        </div>
                    </b-col>
                </b-row>

            </div>

            <b-table
                ref="refUserListTable"
                class="position-relative"
                :items="fetchQuestions"
                responsive
                :fields="tableColumns"
                primary-key="id"
                :sort-by.sync="sortBy"
                show-empty
                empty-text="No matching records found"
                :sort-desc.sync="isSortDirDesc">

                <!-- Column: Question -->
                <template #cell(question)="data">
                    {{ data.item.question.length > 90 ? `${data.item.question.substring(0,90)}...` : data.item.question }}
                </template>

                <!-- Column: Code -->
                <template #cell(code)="data">
                    <b-link
                        :to="{ name: 'questions-edit', params: { id: data.item.id } }"
                        class="font-weight-bold d-block text-nowrap">
                        {{ data.item.code }}
                    </b-link>
                </template>

                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <b-dropdown
                        variant="link"
                        no-caret
                        :right="$store.state.appConfig.isRTL">

                        <template #button-content>
                            <feather-icon
                                icon="MoreVerticalIcon"
                                size="16"
                                class="align-middle text-body"
                            />
                        </template>

                        <b-dropdown-item :to="{ name: 'questions-edit', params: { id: data.item.id } }">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Editar</span>
                        </b-dropdown-item>

                        <b-dropdown-item @click="deleteQuestion(data.item.id)">
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">Eliminar</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </template>

            </b-table>
            <div class="mx-2 mb-2">
                <b-row>

                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Mostrando {{ dataMeta.from }} al {{ dataMeta.to }} de {{ dataMeta.of }} registros</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end">

                        <b-pagination
                            v-model="currentPage"
                            :total-rows="totalUsers"
                            :per-page="perPage"
                            first-number
                            last-number
                            class="mb-0 mt-1 mt-sm-0"
                            prev-class="prev-item"
                            next-class="next-item">

                            <template #prev-text>
                                <feather-icon
                                    icon="ChevronLeftIcon"
                                    size="18"
                                />
                            </template>
                            <template #next-text>
                                <feather-icon
                                    icon="ChevronRightIcon"
                                    size="18"
                                />
                            </template>
                        </b-pagination>

                    </b-col>

                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>

    import {useToast} from 'vue-toastification/composition';
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import {
        BButton,
        BCard,
        BCol,
        BDropdown,
        BDropdownItem,
        BFormInput,
        BLink,
        BPagination,
        BRow,
        BTable,
    } from 'bootstrap-vue';
    import vSelect from 'vue-select';
    import store from '@/store';
    import { onUnmounted, ref } from '@vue/composition-api';
    import useQuestionsList from './useQuestionsList';
    import questionStoreModule from '../questionStoreModule';
    import QuestionImportFile from './QuestionImportFile.vue';

    export default {
        components: {
            QuestionImportFile,

            BCard,
            BRow,
            BCol,
            BFormInput,
            BButton,
            BTable,
            BLink,
            BDropdown,
            BDropdownItem,
            BPagination,
            vSelect,
        },
        setup() {

            const isAddNewUserSidebarActive = ref(false);

            // Use toast
            const toast = useToast();

            const USER_APP_STORE_MODULE_NAME = 'app-user';

            // Register module
            if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, questionStoreModule);

            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME);
            });

            const {
                fetchQuestions,
                tableColumns,
                perPage,
                currentPage,
                totalUsers,
                dataMeta,
                perPageOptions,
                searchQuery,
                sortBy,
                isSortDirDesc,
                refUserListTable,
                refetchData,
            } = useQuestionsList();

            const deleteQuestion = (id) => {
                store.dispatch('app-user/deleteQuestion', { id })
                .then( (response) => {
                    refetchData();
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: response.data.message,
                            icon: 'CheckIcon',
                            variant: 'success',
                        },
                    });
                })
                .catch( () => {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Error al eliminar la pregunta',
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                        },
                    });
                });
            };

            return {
                // Sidebar
                isAddNewUserSidebarActive,

                fetchQuestions,
                tableColumns,
                perPage,
                currentPage,
                totalUsers,
                dataMeta,
                perPageOptions,
                searchQuery,
                sortBy,
                isSortDirDesc,
                refUserListTable,
                refetchData,

                // Filter
                deleteQuestion
            }
        }
    }

</script>

<style lang="scss" scoped>

    .per-page-selector {
        width: 90px;
    }

</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>