<template>
    <b-sidebar
        id="add-new-user-sidebar"
        :visible="isAddNewUserSidebarActive"
        bg-variant="white"
        sidebar-class="sidebar-lg"
        shadow
        backdrop
        no-header
        right
        @hidden="resetForm"
        @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">
                    <strong>CARGA MASIVA (EXCEL)</strong>
                </h5>

                <feather-icon
                    class="ml-1 cursor-pointer"
                    icon="XIcon"
                    size="16"
                    @click="hide"
                />

            </div>

            <!-- BODY -->
            <validation-observer
                #default="{ handleSubmit }"
                ref="refFormObserver"
            >
                <!-- Form -->
                <b-form
                    class="p-2"
                    @submit.prevent="handleSubmit(onSubmit)"
                    @reset.prevent="resetForm"
                >

                    <!-- Names -->
                    <validation-provider
                        #default="validationContext"
                        name="archivo"
                        rules="required">
                        <!-- Avatar -->
                        <b-form-group
                            label="Archivo"
                            label-for="photo"
                        >
                            <b-form-file
                                id="photo"
                                v-model="userData.file"
                                :state="userData.stateFile"
                                @change="onFileChange"
                                accept=".xls,.xlsx"
                                placeholder="Elija un archivo o suéltelo aquí ..."
                                drop-placeholder="Suelta el archivo aquí ...."
                            />
                        </b-form-group>
                    </validation-provider>

                    <!-- Form Actions -->
                    <div class="d-flex mt-2">
                        <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            class="mr-2"
                            type="submit"
                        >
                            IMPORTAR
                        </b-button>
                        <b-button
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            type="button"
                            variant="outline-secondary"
                            @click="hide"
                        >
                            CANCELAR
                        </b-button>
                    </div>

                </b-form>
            </validation-observer>
        </template>
    </b-sidebar>
</template>

<script>

    import { useToast } from 'vue-toastification/composition';
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import { BSidebar, BForm, BFormGroup, BFormFile, BFormInvalidFeedback, BButton } from 'bootstrap-vue';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { ref } from '@vue/composition-api';
    import { required } from '@validations';
    import formValidation from '@core/comp-functions/forms/form-validation';
    import Ripple from 'vue-ripple-directive';
    import axios from 'axios';

    export default {
        components: {
            BSidebar,
            BForm,
            BFormGroup,
            BFormFile,
            BFormInvalidFeedback,
            BButton,

            // Form Validation
            ValidationProvider,
            ValidationObserver,
        },
        directives: {
            Ripple,
        },
        model: {
            prop: 'isAddNewUserSidebarActive',
            event: 'update:is-add-new-user-sidebar-active',
        },
        props: {
            isAddNewUserSidebarActive: {
                type: Boolean,
                required: true,
            }
        },
        data() {
            return {
                required
            }
        },
        setup(props, { emit }) {

            // Use toast
            const toast = useToast();

            const blankUserData = {
                file: null,
                stateFile: null
            };

            const userData = ref(JSON.parse(JSON.stringify(blankUserData)));
            const resetuserData = () => {
                userData.value = JSON.parse(JSON.stringify(blankUserData));
            };

            const onSubmit = () => {
                let formData = new FormData();
                formData.append("file", userData.value.file);
                axios
                    .post(`${process.env.VUE_APP_HOST}/api/dashboard/question/import`, formData, {
                        headers: { 'Content-Type': 'multipart/form-data'}
                    })
                    .then( (response) => {
                        emit('refetch-data');

                        toast({
                            component: ToastificationContent,
                            props: {
                                title: response.data.message,
                                icon: 'CheckIcon',
                                variant: 'success',
                            },
                        });

                        emit('update:is-add-new-user-sidebar-active', false);
                    })
                    .catch( () => {
                        toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Ha ocurrido un error al importar el archivo.',
                                icon: 'AlertTriangleIcon',
                                variant: 'danger',
                            },
                        });
                    });
            };

            const onFileChange = (e) => {
                const file = e.target.files[0];
                if (file) {
                    userData.value.file = file;
                    userData.value.stateFile = Boolean(userData.value.file);
                } else {
                    userData.value.file = null;
                    userData.value.stateFile = null;
                }
            };

            const {
                refFormObserver,
                getValidationState,
                resetForm,
            } = formValidation(resetuserData);

            return {
                userData,
                onFileChange,
                onSubmit,

                refFormObserver,
                getValidationState,
                resetForm,
            }
        },
    }

</script>